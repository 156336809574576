import { useDisclosure } from '@chakra-ui/hooks';
import { Icon } from '@chakra-ui/icon';
import { Box, Link, Text } from '@chakra-ui/layout';
import { useBreakpoint } from '@chakra-ui/media-query';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import noop from 'lodash/noop';
import {
  Children,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdExpandMore } from 'react-icons/md';
import { itemDisplay } from './Header';
import { Visibility, VISIBILITY_TYPES } from '../Navigation.types';

interface MenuItemProps extends PropsWithChildren {
  href: string;
  label: string;
  icon?: string;
  tight?: boolean;
  visibility?: Visibility;
}

const MainMenuItem: React.FC<MenuItemProps> = ({
  href,
  label,
  icon,
  tight = false,
  visibility = VISIBILITY_TYPES['ALWAYS'],
  children,
}) => {
  const submenuRef = useRef<HTMLDivElement>(null);
  const [submenuHeight, setSubmenuHeight] = useState(0);
  const breakpoint = useBreakpoint();
  const isMobile = ['xs', 'sm', 'md', 'lg'].includes(breakpoint);
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const toggleMenu = (e: MouseEvent) => {
    e.preventDefault();
    onToggle();
  };

  useEffect(() => {
    if (submenuRef.current) {
      submenuRef.current.style.maxHeight = 'none';
      const box = submenuRef.current.getBoundingClientRect();
      setSubmenuHeight(box.height);
      submenuRef.current.style.maxHeight = '';
    }
  }, [submenuRef]);

  const hasSubmenu = children && Children.count(children) > 0;
  return (
    <Box
      display={itemDisplay(visibility)}
      data-testid="box-header"
      position="relative"
      onMouseOver={isMobile ? noop : onOpen}
      onMouseOut={isMobile ? noop : onClose}
      onFocus={isMobile ? noop : onOpen}
      onBlur={isMobile ? noop : onClose}
      onClick={isMobile && hasSubmenu ? toggleMenu : noop}
    >
      <Link
        href={href}
        display="flex"
        alignItems="center"
        whiteSpace="nowrap"
        py={{ base: 0, xl: tight ? 0 : 2 }}
        px={{ base: 6, xl: 0 }}
        color="gray.700"
        textDecoration="none"
      >
        <Text
          as="span"
          fontSize="sm"
          display="flex"
          alignItems="center"
          gap={2}
          color="gray.700"
        >
          {icon && (
            <Icon
              as={STRING_TO_ICON_CLASS[icon]}
              boxSize={18}
              display="block"
            />
          )}
          {label}
        </Text>
        {hasSubmenu && (
          <Icon
            as={MdExpandMore}
            boxSize="24px"
            transition="transform .2s ease"
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          />
        )}
      </Link>
      {hasSubmenu && (
        <Box
          ref={submenuRef}
          position={{ base: 'static', xl: 'absolute' }}
          ml={-5}
          bg="white"
          borderRadius={6}
          boxShadow={{ base: 'none', xl: 'lg' }}
          border={{ base: 0, xl: '1px' }}
          borderColor={{ base: 'none', xl: 'gray.50' }}
          pointerEvents={isOpen ? 'auto' : 'none'}
          transition={{ base: 'max-height .5s ease', xl: 'opacity .2s ease' }}
          overflow="hidden"
          maxHeight={{ base: isOpen ? submenuHeight : 0, xl: 'none' }}
          opacity={{ base: 100, xl: isOpen ? 100 : 0 }}
          zIndex={10}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            gap={2}
            py={4}
            px={5}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MainMenuItem;

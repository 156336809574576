import { Icon } from '@chakra-ui/icon';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import useSearchStepSubmission from '@components/Analytics/events/SearchStepSubmission';
import useSearchSubmission from '@components/Analytics/events/SearchSubmission';
import { HeaderNavigation } from '@components/Navigation';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import useSearch from '@hooks/useSearch';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { itemDisplay } from './Header';
import MainMenuItem from './MainMenuItem';
const AutocompleteInput = dynamic(
  () => import('@components/AutocompleteInput/AutocompleteInput')
);
const HeaderJumpLinks = dynamic(
  () => import('@components/Navigation/Header/HeaderJumpLinks')
);
const HeaderWhoAreYouSearchingFor = dynamic(
  () => import('@components/Navigation/Header/HeaderWhoAreYouSearchingFor')
);

const HeaderMainItems: React.FC<{
  main: HeaderNavigation['main'];
  metadata: HeaderNavigation['metadata'];
}> = ({ main, metadata }) => {
  const { buildSearchUrl } = useSearch();

  const searchStepSubmission = useSearchStepSubmission();
  const searchSubmission = useSearchSubmission();

  const [keyword, setKeyword] = useState('');
  const searchPageUrl = buildSearchUrl({ keyword });

  const handleSearchEvents = () => {
    const searchItem = main.filter(
      (item) => item.type === 'search' || item.type === 'predictive_search'
    )?.[0];

    const stepInstanceId = uuidv4();
    const searchTemplateId = searchItem.id || uuidv4();
    const stepId = uuidv5(searchItem.text, searchTemplateId);
    const searchInstanceId = uuidv4();

    searchStepSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      step_id: stepId,
      step_instance_id: stepInstanceId,
      step_index: 1,
      step_content: [
        {
          prompt_id: uuidv5(searchItem.text, searchTemplateId),
          prompt_type: 'text',
          prompt_instance_id: uuidv4(),
          prompt_index: 1,
          prompt_value: searchItem.text,
          response_array: [
            {
              response_value: keyword,
              response_id: uuidv4(),
            },
          ],
        },
      ],
    });

    searchSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      step_submissions: [
        {
          step_id: stepId,
          step_instance_id: stepInstanceId,
          step_index: 1,
        },
      ],
    });
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    handleSearchEvents();
    location.assign(searchPageUrl);
  };

  return (
    <>
      {main
        // Filter out items that don't have an icon or text.
        .filter((item) => item.icon || item?.text)
        .map((item, i) => {
          switch (item.type) {
            case 'search':
              return (
                <form
                  style={{ width: '100%' }}
                  onSubmit={handleSearchSubmit}
                  key={`search-${i}`}
                >
                  <InputGroup
                    key={`item-${i}`}
                    maxWidth={320}
                    width="100%"
                    display={itemDisplay(item.visibility)}
                  >
                    <Input
                      name="location"
                      value={keyword || ''}
                      onChange={(e) => setKeyword(e.target.value)}
                      placeholder={item.text}
                      aria-label="search-keyword"
                    />
                    {item.icon && (
                      <InputRightElement>
                        <Icon
                          as={STRING_TO_ICON_CLASS[item.icon]}
                          color="gray.300"
                          width={6}
                          height={6}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </form>
              );
            case 'predictive_search':
              return (
                <form
                  style={{ width: '100%' }}
                  onSubmit={handleSearchSubmit}
                  key={`predictive_search-${i}`}
                >
                  <InputGroup
                    key={`item-${i}`}
                    maxWidth={320}
                    width="100%"
                    display={itemDisplay(item.visibility)}
                    isolation="unset"
                  >
                    <AutocompleteInput
                      aria-label="search-keyword"
                      bg="white"
                      size="lg"
                      defaultValue={keyword || ''}
                      placeholder={item.text}
                      setKeyword={setKeyword}
                      name="location"
                      listContainerProps={{
                        zIndex: 10000,
                      }}
                      fontSize="md"
                      padding="0 40px 0 16px"
                      height={10}
                      borderColor="inherit"
                    />
                    {item.icon && (
                      <InputRightElement>
                        <Icon
                          as={STRING_TO_ICON_CLASS[item.icon]}
                          color="gray.300"
                          width={6}
                          height={6}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </form>
              );
            case 'jump_links':
              return (
                <HeaderJumpLinks
                  key="jump_links"
                  title={item.text}
                  titleColor={{
                    color: item.textColor,
                    range: item.textColorRange,
                  }}
                  visibility={item.visibility}
                />
              );
            case 'whoAreYouSearchingFor':
              return (
                <HeaderWhoAreYouSearchingFor
                  key="who_are_you"
                  sectionTitle={item.text}
                  buttonVariant={item.variant || 'outline'}
                  buttonColor={item.textColor}
                  inquiryForm={item.inquiryForm}
                  visibility={item.visibility}
                  metadata={metadata}
                />
              );
            default:
              return (
                <MainMenuItem
                  key={`item-${i}`}
                  href={item.link.url}
                  label={item.text}
                  icon={item.icon}
                  visibility={item.visibility}
                >
                  {item.children.map((child, j) => (
                    <MainMenuItem
                      key={`item-${i}-subitem-${j}`}
                      href={child.url}
                      label={child.text}
                      tight
                    />
                  ))}
                </MainMenuItem>
              );
          }
        })}
    </>
  );
};

export default HeaderMainItems;
